import { useState, useEffect } from "react";
import { map } from "lodash/fp";
import { PadContainer, ReactInputs, inputs, Circle } from "./ControlBtnStyles";
import { useSelector } from "react-redux";
// import { showWarnToast } from "../toast/toastType";
import { toast } from "react-toastify";
import SignalingChannel from "../../lib/signaling-channel";
import CustomControlBtn from "./CustomControlBtn";
import useHasFocus from "../../custom-hooks/useHasFocus";
import axios from "axios";

let decimalFixed = 2;
let intervalFreq = 10;

let PEER_ID;
let PEER_TYPE = "admin";
let Target;
let channel;

const controlPositions = ["L", "R"];
const analogInputs = ["L", "R"];
const shoulderInputs = ["L2", "L1", "R2", "R1"];
const digitalInputs = [
  "dpadUp",
  "dpadDown",
  "dpadLeft",
  "dpadRight",
  "A",
  "B",
  "X",
  "Y",
  "start",
  "select",
  "home",
];

function Stick({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
  conlock,
  latency,
}) {
  const [x, y] = module.getStick(inputName).value;
  const { pressed } = module.getButton(`${inputName}3`);
  const StickComponent = inputs[inputName];
  const windowFocused = useHasFocus();

  useEffect(() => {
    //Dead-Band Code
    let Dead_Band_X_Max = 0.0;
    let Dead_Band_X_Min = 0.0;
    let Max_V_X = 0.0;
    // let Min_V_X = 0.0;
    let Dead_Band_Y_Max = 0.0;
    let Dead_Band_Y_Min = 0.0;
    let Max_V_Y = 0.0;
    // let Min_V_Y = 0.0;
    let v_y = 0.0;
    let v_x = 0.0;

    if (inputName === "R") {
      Dead_Band_X_Max = 0.1;
      Dead_Band_X_Min = -0.1;
      Max_V_X = 1.0;
      // Min_V_X = 1.0
      Dead_Band_Y_Max = 0.1;
      Dead_Band_Y_Min = -0.1;
      Max_V_Y = 1.0;
      // Min_V_Y = -1.0;
      v_y = 0.0;
      v_x = 0.0;

      if (x >= Dead_Band_X_Max) {
        v_x = Max_V_X * (1.0 / (1.0 - Dead_Band_X_Max)) * (x - Dead_Band_X_Max);
      } else if (x <= Dead_Band_X_Min) {
        v_x = Max_V_X * (1.0 / (1.0 + Dead_Band_X_Min)) * (x - Dead_Band_X_Min);
      } else {
        v_x = 0.0;
      }

      if (y >= Dead_Band_Y_Max) {
        v_y = Max_V_Y * (1.0 / (1.0 - Dead_Band_Y_Max)) * (y - Dead_Band_Y_Max);
      } else if (y <= Dead_Band_Y_Min) {
        v_y = Max_V_Y * (1.0 / (1.0 + Dead_Band_Y_Min)) * (y - Dead_Band_Y_Min);
      } else {
        v_y = 0.0;
      }
    } else if (inputName === "L") {
      Dead_Band_X_Max = 0.1;
      Dead_Band_X_Min = -0.1;
      Max_V_X = 1.0;
      // Min_V_X = 1.0;
      Dead_Band_Y_Max = 0.1;
      Dead_Band_Y_Min = -0.1;
      Max_V_Y = 1.0;
      // Min_V_Y = 1.0;
      v_y = 0.0;
      v_x = 0.0;

      //Added these conditions after 10/06/2023
      if (x >= Dead_Band_X_Max) {
        v_x = Max_V_X * (1.0 / (1.0 - Dead_Band_X_Max)) * (x - Dead_Band_X_Max);
      } else if (x <= Dead_Band_X_Min) {
        v_x = Max_V_X * (1.0 / (1.0 + Dead_Band_X_Min)) * (x - Dead_Band_X_Min);
      } else {
        v_x = 0.0;
      }

      if (y >= Dead_Band_Y_Max) {
        v_y = Max_V_Y * (1.0 / (1.0 - Dead_Band_Y_Max)) * (y - Dead_Band_Y_Max);
      } else if (y <= Dead_Band_Y_Min) {
        v_y = Max_V_Y * (1.0 / (1.0 + Dead_Band_Y_Min)) * (y - Dead_Band_Y_Min);
      } else {
        v_y = 0.0;
      }
    }

    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            axis: {
              ...allButtons.axis,
              [inputName]: {
                ...allButtons.axis[inputName],
                x: -v_x.toFixed(decimalFixed), //Reversed x and y values to negetive for both L and R after 10/06/2023
                y: -v_y.toFixed(decimalFixed),
              },
            },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, setAllButtons, x, y]);

  useEffect(() => {
    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [`${inputName}3`]: +pressed },
          }
        : { ...allButtons }
    );

    if (windowFocused) {
      channel && channel.send(allButtons);
    }
  }, [conlock, inputName, pressed, setAllButtons, windowFocused]);

  return (
    <StickComponent
      onClick={() => {
        if (module.isConnected()) {
          module.stickBindOnPress(inputName, () => setWaitingFor(null));
          setWaitingFor(inputName);
        }
      }}
      style={{
        transform: `translate(${x * 65}px, ${y * 65}px)`,
        backgroundColor: pressed ? pressedColor : "",
      }}
    />
  );
}

function Digital({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
  conlock,
  latency,
}) {
  const { pressed } = module.getButton(inputName);
  const DigitalComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [inputName]: +pressed },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, pressed, setAllButtons]);

  return (
    <DigitalComponent
      style={{ backgroundColor: pressed ? pressedColor : "" }}
      onClick={() => {
        if (module.isConnected()) {
          module.buttonBindOnPress(inputName, () => setWaitingFor(null));
          setWaitingFor(inputName);
        }
      }}
    />
  );
}

function Shoulder({
  inputName,
  module,
  allButtons,
  setAllButtons,
  conlock,
  latency,
}) {
  const { value } = module.getButton(inputName);
  const ShoulderComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            buttons: {
              ...allButtons.buttons,
              [inputName]: value === 1 ? Math.ceil(value) : 0,
            },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, setAllButtons, value]);

  return (
    <div
      style={{
        position: "absolute",
        transform: `translateY(${value * 10}px)`,
        width: "100%",
        height: "100%",
      }}
    >
      <ShoulderComponent />
    </div>
  );
}

export default function ControlBtn({
  // backgroundColor,
  module,
  pressedColor,
  conlock,
  robotId,
  state,
  setState,
  latency,
  signallingChannel,
  setSignallingChannel,
  controllerMessage,
  setControllerMessage,
  controllerData,
  setControllerData,
  setOpenDuplicateTabModal,
  peerId,
  setPeerId,
  target,
  setTarget,
  setOpenAcceptTransferModal,
  setAccessTimer,
}) {
  const [, setWaitingFor] = useState(null);
  const [allButtons, setAllButtons] = useState({
    buttons: {
      L1: 0,
      L2: 0,
      L3: 0,
      R1: 0,
      R2: 0,
      R3: 0,
      dpadUp: 0,
      dpadDown: 0,
      dpadLeft: 0,
      dpadRight: 0,
      A: 0,
      B: 0,
      X: 0,
      Y: 0,
      start: 0,
      select: 0,
      home: 0,
    },
    axis: {
      L: { x: 0, y: 0 },
      R: { x: 0, y: 0 },
    },
    vlatency: latency,
  });

  const { user } = useSelector((state) => state.userAuthReducer);

  Target = robotId;
  // Target = "OB02221015";

  const windowFocused = useHasFocus();
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const emailId = localStorage.getItem("useremail");

  let uniqueId = localStorage.getItem("uniqueId");

  const isConnected = (peerId) => {
    return axios
      .post(`${process.env.REACT_APP_SIGNALING_SERVER_URL}/isconnected`, {
        peerId: peerId,
      })
      .then((response) => {
        return response;
      });
  };

  const RobotNotConnectedToast = ({
    retryRobotSignallingConnection,
    closeToast,
  }) => (
    <div className="flex flex-col items-center w-full gap-1">
      <span>Robot is not connected to signalling server. Please retry!!</span>
      <span
        className="w-8 h-8"
        onClick={() => retryRobotSignallingConnection()}
      >
        <svg
          className="w-8 h-8 p-1 bg-[#79630b] rounded-md"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            d="M20,8 C18.5974037,5.04031171 15.536972,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 L12,21 C16.9705627,21 21,16.9705627 21,12 M21,3 L21,9 L15,9"
          ></path>
        </svg>
      </span>
    </div>
  );

  const retryRobotSignallingConnection = () => {
    isConnected(Target)
      .then((response) => {
        // console.log(response.data.status);
        if (response.data.status === true) {
          // console.log("ROBOT IS ALREADY CONNECTED.");
          // console.log(response.data.status);
          // showWarnToast("Robot is already connected to signalling server!");
          channel.connect();
          channel.requestCom({
            peerId: PEER_ID,
            target: Target,
          });

          channel.onRequestComMessage = (message) => {
            // console.log("FRONTEND:", message);
            setControllerMessage(message.incomingMessage);
            setControllerData(message);
          };

          channel.onRequestAccessMessage = (message) => {
            // console.log("FRONTEND:", message);
            // setControllerMessage(message.incomingMessage);
            setControllerData(message);
            setOpenAcceptTransferModal(true);
          };

          channel.onAcceptAccessMessage = (message) => {
            // console.log("FRONTEND:", message);
            // setControllerMessage(message.incomingMessage);
            setControllerData(message);
          };

          setSignallingChannel(channel);
        } else {
          toast.warn(
            <RobotNotConnectedToast
              retryRobotSignallingConnection={retryRobotSignallingConnection}
            />,
            {
              position: "bottom-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .catch((error) => {
        console.error("ROBOT ID Connected API Failed. " + error.message);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("uniqueId")) {
      
      PEER_ID = (firstName || lastName) ? firstName + "_" + lastName + "_" + uniqueId : emailId + "_" + uniqueId ;
      setPeerId(PEER_ID);
      setTarget(Target);

      isConnected(PEER_ID)
        .then((response) => {
          if (response.data.status === false) {
            channel = new SignalingChannel(
              PEER_ID,
              PEER_TYPE,
              process.env.REACT_APP_SIGNALING_SERVER_URL,
              process.env.REACT_APP_TOKEN
            );
            channel.onRequestComMessage = (message) => {
              console.log("FRONTEND:", message);
              setControllerMessage(message.incomingMessage);
              setControllerData(message);
            };

            channel.onRequestAccessMessage = (message) => {
              // console.log("FRONTEND:", message);
              // setControllerMessage(message.incomingMessage);
              setControllerData(message);
              setAccessTimer(message.timer);
              setOpenAcceptTransferModal(true);
            };

            channel.onAcceptAccessMessage = (message) => {
              // console.log("FRONTEND:", message);
              setControllerMessage(message.incomingMessage);
              setControllerData(message);
            };
            setSignallingChannel(channel);
            retryRobotSignallingConnection();

            localStorage.setItem("PEER_ID", PEER_ID);
          } else {
            // setOpenDuplicateTabModal(true);
            // showWarnToast(
            //   "The user is already connected to signalling server."
            // );
          }
        })
        .catch((error) => {
          console.error("Robot ID Connected API Failed. " + error.message);
        });
    }
  }, [firstName, lastName, uniqueId, setSignallingChannel]);

  useEffect(() => {
    if (conlock && Target) {
      const initialButtons = {
        buttons: { ...allButtons.buttons },
        axis: { ...allButtons.axis },
        vlatency: allButtons.vlatency,
      };

      if (windowFocused) {
        channel && channel.sendTo(Target, initialButtons);
      }

      let sendClientOnce = true;
      const intervalId = setInterval(() => {
        if (state.streamclient && sendClientOnce) {
          const manipulatedButtons = {
            buttons: { ...allButtons.buttons, L1: 1 },
            axis: { ...allButtons.axis },
          };
          if (windowFocused) {
            channel && channel.sendTo(Target, manipulatedButtons);
          }
          sendClientOnce = false;
        }
        if (windowFocused) {
          channel && channel.sendTo(Target, allButtons);
        }
      }, intervalFreq);

      return () => {
        clearInterval(intervalId);
      };
    }
    if (state.streamclient) {
      const manipulatedButtons = {
        buttons: { ...allButtons.buttons, L1: 1 },
        axis: { ...allButtons.axis },
      };

      if (windowFocused) {
        channel && channel.sendTo(Target, manipulatedButtons);
      }
    }
  }, [conlock, allButtons, state, setState, windowFocused]);

  useEffect(() => {
    setAllButtons((allButtons) => ({
      ...allButtons,
      vlatency: latency,
    }));
  }, [latency, setAllButtons]);

  return (
    <>
      {map(
        (inputName) => (
          <div style={{ display: "none" }} key={inputName}>
            <Shoulder
              inputName={inputName}
              module={module}
              setAllButtons={setAllButtons}
              allButtons={allButtons}
              conlock={conlock}
              latency={latency}
            />
          </div>
        ),
        shoulderInputs
      )}
      {map(
        (inputName) => (
          <div style={{ display: "none" }} key={inputName}>
            <Digital
              inputName={inputName}
              pressedColor={pressedColor}
              module={module}
              setWaitingFor={setWaitingFor}
              setAllButtons={setAllButtons}
              allButtons={allButtons}
              conlock={conlock}
              latency={latency}
            />
          </div>
        ),
        digitalInputs
      )}
      <PadContainer disconnected={!module.isConnected()}>
        <ReactInputs
          style={{
            width: "410px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {analogInputs.map(
            (inputName, index) => (
              <Circle
                key={"controller__circle__" + index}
                // style={{
                //   backgroundImage: `url(${controll_btn_images[index]})`,
                // }}
              >
                <CustomControlBtn controlPositions={controlPositions[index]} />
                <Stick
                  key={"controller__stick__" + index}
                  inputName={inputName}
                  pressedColor={pressedColor}
                  module={module}
                  setWaitingFor={setWaitingFor}
                  setAllButtons={setAllButtons}
                  allButtons={allButtons}
                  conlock={conlock}
                  latency={latency}
                />
              </Circle>
            ),
            analogInputs
          )}
        </ReactInputs>
      </PadContainer>
    </>
  );
}
