import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { useState } from "react";
import { RxTimer } from "react-icons/rx";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../toast/toastType";

const openAccessTransferModalStyling = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "transparent !important",
  outline: "none",
  p: 4,
};

const AccessTransferModal = ({
  openAccessTransferModal,
  setOpenAccessTransferModal,
  robotId,
  controllerData,
  signallingChannel,
  peerId,
  target,
}) => {
  const { user } = useSelector((state) => state.userAuthReducer);

  const [addTimer, setAddTimer] = useState(false);
  const [timer, setTimer] = useState(undefined);

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const emailId = localStorage.getItem("useremail");

  const handleOpenAccessTransferModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenAccessTransferModal(false);
  };

  const handleRequestAccess = () => {
    if (!isNaN(Number(timer))) {
      if (Number(timer) >= 3 && Number(timer) <= 60) {
        signallingChannel.requestAccess({
          peerId: peerId,
          target: target,
          timer: Number(timer),
        });
        setOpenAccessTransferModal(false);
        setAddTimer(false);
        setTimer(undefined);
      } else {
        showErrorToast(
          "The Timer value should be between 3 seconds to 60 seconds!!"
        );
      }
    } else if (isNaN(Number(timer))) {
      signallingChannel.requestAccess({
        peerId: peerId,
        target: target,
      });
      setOpenAccessTransferModal(false);
    }
  };

  return (
    <Modal
      open={openAccessTransferModal}
      backdrop="static"
      onClose={handleOpenAccessTransferModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgb(0,0,0,0.84)",
          },
          timeout: 500,
        },
      }}
    >
      <Fade in={openAccessTransferModal}>
        <Box sx={openAccessTransferModalStyling}>
          <div className="flex flex-col justify-center w-full gap-3 text-center item-center">
            <div className="flex flex-col items-center gap-2.5">
              <span className="w-20 h-20 flex items-center justify-center rounded-full bg-[#BEC4DE] text-[#1C1E2B] text-4xl uppercase font-bold border-white border-2">
                {/* {firstName && firstName[0]}
                {lastName && lastName[0]} */}
                {firstName || lastName ? (
                  <>
                    {firstName && firstName[0]}
                    {lastName && lastName[0]}
                  </>
                ) : (
                  <>
                    {emailId &&
                      emailId !== "undefined" &&
                      emailId !== "null" &&
                      emailId[0]}
                  </>
                )}
              </span>
              <span className="text-xl font-normal text-white capitalize">
                {/* {firstName + " " + lastName} */}

                {firstName || lastName ? (
                    <>
                    {firstName + " " + lastName} 
                    </>
                  ):(
                    {emailId}
                  )}
            

              </span>
            </div>
            <div className="text-center item-center justify-center w-full flex flex-col text-[#BEC4DE] text-[26px] font-bold">
              <span>Are you sure you want to</span>
              <span>
                take control of{" "}
                <span className="text-white">Robot "{target}"</span>
              </span>
              <span>
                from{" "}
                {controllerData?.assignedPeerId
                  ?.split("_")
                  ?.slice(0, -1)
                  ?.join(" ")}
                ?
              </span>
            </div>
            <div className="flex items-center justify-between w-full h-12 gap-2 mt-4">
              <span
                onClick={handleRequestAccess}
                className="h-full flex justify-center grow shrink-0 basis-auto items-center text-[#1C1E2B] bg-[#BEC4DE] rounded-md text-2xl font-bold cursor-pointer"
              >
                Yes
              </span>
              <span
                onClick={handleOpenAccessTransferModal}
                className="h-full flex justify-center grow shrink-0 basis-auto items-center text-[#BEC4DE] bg-transparent border border-white rounded-md text-2xl font-bold cursor-pointer"
              >
                No
              </span>

              {!addTimer ? (
                <span className="h-full flex justify-center grow-0 shrink basis-auto items-center text-[#BEC4DE] bg-transparent border border-white rounded-md text-2xl font-bold">
                  <span
                    className="flex items-center gap-1 px-2 cursor-pointer"
                    onClick={() => {
                      setAddTimer(true);
                      setTimer("3");
                    }}
                  >
                    <RxTimer className="w-6 h-6" />
                    <span className="text-xl">Add Timer</span>
                  </span>
                </span>
              ) : (
                <div className="flex flex-col items-center justify-center h-full gap-1">
                  <div className="flex items-center justify-start w-full h-2 px-1">
                    <span className="text-[9px]">Min - 3</span>
                  </div>
                  <div className="flex items-center gap-1 grow-0 shrink basis-auto ">
                    <span className="h-full flex justify-center items-center text-[#BEC4DE] bg-transparent border border-white rounded-md text-2xl font-bold">
                      <div className="flex items-center w-20 h-full px-2">
                        <input
                          type="number"
                          value={timer}
                          className="w-full h-full bg-transparent focus:outline-none focus-within:border-b-2 focus-within:border-[#BEC4DE]"
                          onChange={(event) => {
                            if (
                              // !/^$|^(3|[4-9]|[1-5]\d|60)$/.test(
                              //   event.target.value
                              // )
                              !/^\d{0,2}$/.test(event.target.value)
                            ) {
                              return false;
                            } else {
                              setTimer(event.target.value);
                            }
                          }}
                        />
                        <span className="text-sm text-white font-poppins">
                          sec
                        </span>
                      </div>
                    </span>
                    <span
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => {
                        setAddTimer(false);
                        setTimer(undefined);
                      }}
                    >
                      <IoIosCloseCircleOutline className="w-full h-full" />
                    </span>
                  </div>
                  <div className="flex items-center justify-end w-full h-2 px-7">
                    <span className="text-[9px]">Max - 60</span>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <span className="text-sm italic font-light font-lilita">
                By confirming takeover, you will be interrupting the existing
                teleoperation session.
              </span>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AccessTransferModal;
